@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap");

@layer base {
  body {
    @apply font-[Raleway];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

#navbar {
  z-index: 100;
}

.card-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
  transition: 0.5s;
}

.card-div:hover {
  background-image: linear-gradient(
    45deg,
    rgba(16, 31, 51, 0.8),
    hsla(241, 45%, 35%, 0.8)
  ) !important;
  scale: 1.02;
}

.view-work:hover {
  color: white;
  font-weight: bold;
  background-color: #ff6666;
  cursor: pointer;
}

.view-work {
  width: 200px;
  height: 60px;
  font-size: 1.1rem;
  border: 2px solid #ff6666;
  background-color: #0a192f;
  color: #ff6666;
  transition: 0.4s;
}

#lets-connect {
  transition: 0.4s;
}

#home h1 {
  animation: 3s fade-in;
}

#home h2 {
  animation: 2s slide-in;
}

@keyframes slide-in {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  #work::before {
    display: block;
    content: "";
    height: 80px;
  }
}
